import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import { ILoadItemsProps } from '@/shared/interfaces/classes/form.interfaces'
import SelectItem from '@/shared/classes/form/fields/Select/select-item'

export default class SearchableField extends Field {
  type: FieldTypes = FieldTypes.searchable
  items: SelectItem[] = []
  isAttached: boolean = true
  loadItemsProps!: ILoadItemsProps
  afterSelect!: (value: any) => void
  addButtonProps = {
    isIcon: false,
    openDialog: null
  }
  onItemSelect!: (value: any, key: string) => void

  loadItems(loadItemsProps: ILoadItemsProps): this {
    this.loadItemsProps = loadItemsProps
    return this
  }

  isMultiple(): this {
    this.type = FieldTypes.multipleSearchable
    return this
  }

  setIsAttached(isAttached: boolean): this {
    this.isAttached = isAttached
    return this
  }

  setAfterSelect(afterSelect: (value: any) => void): this {
    this.afterSelect = afterSelect
    return this
  }

  setAddButtonProps(addButtonProps: any): this {
    this.addButtonProps = addButtonProps
    return this
  }

  setOnItemSelect(onItemSelect: (value: any, key: string) => void): this {
    this.onItemSelect = onItemSelect
    return this
  }
}
