var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',[_c('div',{staticClass:"d-md-flex d-block flex-wrap"},[_c('FormField',{attrs:{"form":_vm.form,"field":_vm.projectsField,"full-key":_vm.projectsField.key}}),_c('div',{staticClass:"w-100 d-block d-md-flex"},[_c('FormField',{attrs:{"form":_vm.form,"field":_vm.fromField,"full-key":_vm.fromField.key}}),_c('FormField',{staticClass:"ml-md-5",attrs:{"form":_vm.form,"field":_vm.toField,"full-key":_vm.toField.key}})],1),_c('FormField',{attrs:{"form":_vm.form,"field":_vm.employeesField,"full-key":_vm.employeesField.key}}),_c('FormField',{attrs:{"form":_vm.form,"field":_vm.taskTypesField,"full-key":_vm.taskTypesField.key}})],1),_c('div',{staticClass:"d-flex flex-wrap"},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","loading":_vm.loading === _vm.types.pivot},on:{"click":function($event){return _vm.generate(_vm.types.pivot)}}},[_vm._v(" "+_vm._s(_vm.__('company.views.reports.index.tabs.projects.form.actions.pivot'))+" ")]),_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","loading":_vm.loading === _vm.types.pdf},on:{"click":function($event){return _vm.generate(_vm.types.pdf)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-file-pdf-box")])],1),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading === _vm.types.xls},on:{"click":function($event){return _vm.generate(_vm.types.xls)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-file-excel")])],1)],1),(_vm.data)?_c('div',[_c('PageHeader',{attrs:{"classes":"mt-5","sub-title":_vm.__('company.views.reports.index.tabs.projects.table.title')}}),_c('v-data-table',{staticClass:"elevation-2 mt-5",attrs:{"headers":_vm.headers,"items":_vm.items,"group-by":"project","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var groupBy = ref.groupBy;
var items = ref.items;
var headers = ref.headers;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}},[_c('table',[_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"w-100 d-flex align-center font-weight-medium"},[_vm._v(" "+_vm._s(_vm.__('company.views.reports.index.tabs.projects.table.headers.project'))+" - "+_vm._s(_vm.data[group].name)+" ")])])])])])]}},{key:"group.summary",fn:function(ref){
var group = ref.group;
return [_c('td',{staticClass:"font-weight-medium white caption",attrs:{"colspan":3}},[_c('div',[_vm._v(_vm._s(_vm.__('company.views.reports.index.tabs.projects.table.footers.without-extra-duration'))+": "+_vm._s(_vm.data[group].total_duration))]),_c('div',[_vm._v(_vm._s(_vm.__('company.views.reports.index.tabs.projects.table.footers.only-extra-duration'))+": "+_vm._s(_vm.data[group].total_extra))]),_c('div',[_vm._v(_vm._s(_vm.__('company.views.reports.index.tabs.projects.table.footers.total-duration'))+": "+_vm._s(_vm.data[group].total))])])]}}],null,false,70010684)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }