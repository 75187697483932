import Service from '@/shared/classes/service'
import Company from '@/shared/modules/company/company.model'

export enum GenerateType {
  pivot = 'pivot',
  xls = 'xls',
  pdf = 'pdf'
}

export default class ReportService extends Service {
  static prefix = 'reports'

  static allHours(company: Company, data: any): Promise<any> {
    return this.post(`company/${ company.slug }/reports/all-hours`, data, {
      responseType: data.type === GenerateType.pivot ? 'json' : 'blob',
    })
  }

  static paidHours(company: Company, type: GenerateType, from: string, to: string): Promise<any> {
    return this.get(`company/${ company.slug }/reports/paid-hours?type=${ type }&from=${ from }&to=${ to }`,
      { responseType: type === GenerateType.pivot ? 'json' : 'blob' })
  }

  static employeePaidHoursByProject(company: Company, type: GenerateType, from: string, to: string, employeeUuid: string, projectUuid: string): Promise<any> {
    return this.get(`company/${ company.slug }/reports/employee-paid-hours-by-project?type=${ type }&from=${ from }&to=${ to }&employee_uuid=${ employeeUuid }${ projectUuid ? `&project_uuid=${ projectUuid }` : '' }`, {
      responseType: type === GenerateType.pivot ? 'json' : 'blob',
    })
  }

  static collectedHours(company: Company, type: GenerateType, from: string, to: string, employeeUuid: string): Promise<any> {
    return this.get(`company/${ company.slug }/reports/collected-hours?type=${ type }&from=${ from }&to=${ to }${ employeeUuid ? `&user_uuid=${ employeeUuid }` : ''}`, {
      responseType: type === GenerateType.pivot ? 'json' : 'blob',
    })
  }

  static projects(company: Company, data: any): Promise<any> {
    return this.post(`company/${ company.slug }/reports/projects`, data, {
      responseType: data.type === GenerateType.pivot ? 'json' : 'blob',
    })
  }
}
