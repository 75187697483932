


























































import { Component, Vue } from 'vue-property-decorator'
import ReportService, { GenerateType } from '@/shared/modules/report/ReportService'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import __ from '@/shared/helpers/__'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import FormField from '@/shared/components/form/FormField.vue'
import FormBase from '@/shared/classes/form/form-base'
import moment from 'moment'
import { downloadFile } from '@/shared/helpers/download-file.helper'
import _ from 'lodash'
import { GlobalActions } from '@/shared/store/global/global.actions'
import ProjectsReportGenerate from '@/company/components/reports/ProjectsReportGenerate.vue'
import PageHeader from '@/admin/components/PageHeader.vue'
import { DataTableHeader } from 'vuetify'

@Component({
  components: { PageHeader, FormField },
  methods: { __ }
})
export default class Reports extends Vue {
  types = GenerateType
  projectsField: Field = new Field()
      .setKey('project_uuids')
      .setHidden(true)
  fromField: Field = new Field()
      .setType(FieldTypes.datePicker)
      .setKey('from')
      .setTitle(__('company.views.reports.index.tabs.projects.form.fields.from'))
      .setDense(true)
      .setSize(FieldSizes.half)
  toField: Field = new Field()
      .setType(FieldTypes.datePicker)
      .setKey('to')
      .setTitle(__('company.views.reports.index.tabs.projects.form.fields.to'))
      .setDense(true)
      .setSize(FieldSizes.half)
      .setMeta({
        minKey: () => 'from'
      })
  employeesField: Field = new SearchableField()
      .isMultiple()
      .setKey('employee_uuids')
      .setTitle(__('company.views.reports.index.tabs.projects.form.fields.employees'))
      .setDense(true)
      .loadItems({
        endpoint: `company/${ this.company.slug }/employees`,
        value: 'uuid',
        title: 'full_name',
        perPage: 20
      })
  taskTypesField: Field = new SearchableField()
      .isMultiple()
      .setKey('task_type_uuids')
      .setTitle(__('company.views.reports.index.tabs.projects.form.fields.task-types'))
      .setDense(true)
      .loadItems({
        endpoint: `company/${ this.company.slug }/task-types`,
        value: 'uuid',
        title: 'name',
        perPage: 20
      })
  loading: GenerateType | null = null

  form: FormBase = new FormBase()
      .setData({
        from: moment.utc().startOf('month').format('YYYY-MM-DD'),
        to: moment.utc().endOf('month').format('YYYY-MM-DD'),
        employee_uuids: [],
        task_type_uuids: [],
      })

  data: any = null
  invisibleColumns: any = null
  withExtra: boolean = false
  onlyExtra: boolean = false

  generate(type: GenerateType) {
    _.set(this.form.data, 'type', type)
    _.set(this.form.data, 'project_uuids', [this.$route.params.uuid])

    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: ProjectsReportGenerate,
      maxWidth: 560,
      meta: {
        company: this.company,
        data: this.form.data,
        onSuccess: async (data: any, invisibleColumns: any) => {
          this.invisibleColumns = invisibleColumns
          this.withExtra = data.with_extra
          this.onlyExtra = data.only_extra
          await ReportService.projects(this.company, data)
              .then((response: any) => {
                this.form.setErrors({})

                if (type === GenerateType.pivot) {
                  this.data = response.data
                  return
                }

                downloadFile(response, `projects-report.${ data.type }`)
              })
              .catch((error: any) => this.form.catchErrors(error))
        }
      }
    })
  }

  getHeaderTitle(column: string) {
    if (column === 'duration') {
      if (this.onlyExtra) return __('company.views.reports.index.tabs.projects.table.headers.only-extra-duration')
      if (! this.withExtra) return __('company.views.reports.index.tabs.projects.table.headers.without-extra-duration')
    }

    return __(`company.views.reports.index.tabs.projects.table.headers.${ _.kebabCase(column) }`)
  }

  get headers(): DataTableHeader[] {
    return [
      {
        value: 'project',
        text: __('company.views.reports.index.tabs.projects.table.headers.project'),
        sortable: false,
      },
      ...Object.keys(this.invisibleColumns)
          .filter((column: string) => ! this.invisibleColumns[column])
          .map((column: string) => ({
            value: column,
            text: this.getHeaderTitle(column),
            sortable: false,
          }))
    ]
  }

  get items(): any {
    const data: any = []

    Object.keys(this.data).forEach((projectUuid: string) => {
      data.push(
          ...this.data[projectUuid].tasks.map((task: any) => ({
            project: projectUuid,
            ...task
          }))
      )
    })

    return data
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
