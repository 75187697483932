import _ from 'lodash'

export const downloadFile = (response: any, fileName: string) => {
  const blob = new Blob([response.data], { type: _.get(response, 'headers.content-type') })
  const url = window.URL.createObjectURL(blob)

  const tmpExportLink = document.createElement('a')
  tmpExportLink.href = url

  tmpExportLink.download = fileName
  document.body.appendChild(tmpExportLink)

  tmpExportLink.click()

  setTimeout(() => document.body.removeChild(tmpExportLink))
}