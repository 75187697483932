






import { Component, Prop, Vue } from 'vue-property-decorator'
import FormBase from '@/shared/classes/form/form-base'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import __ from '@/shared/helpers/__'
import FormField from '@/shared/components/form/FormField.vue'
import Form from '@/shared/components/form/Form.vue'
import { GlobalActions } from '@/shared/store/global/global.actions'
import ObjectField from '@/shared/classes/form/fields/object-field'
import _ from 'lodash'
import PageHeader from '@/admin/components/PageHeader.vue'
import { GenerateType } from '@/shared/modules/report/ReportService'
import moment from 'moment'

@Component({
  components: { PageHeader, Form, FormField },
  methods: { __ }
})
export default class ProjectsReportGenerate extends Vue {
  @Prop() meta: any

  columns = ['employee', 'date', 'start_time', 'end_time', 'duration', 'total', 'task_types']

  form: FormBase = new FormBase()
    .setFields([
      new Field()
        .setType(FieldTypes.checkbox)
        .setKey('with_extra')
        .setTitle(__('company.components.views.reports.projects-report-generate.form.fields.with-extra'))
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.checkbox)
        .setKey('only_extra')
        .setTitle(__('company.components.views.reports.projects-report-generate.form.fields.only-extra-checkbox'))
        .setOnChange((value: boolean) => {
          const foundField: Field | any = this.form.fields.find((field: Field) => field.key === 'with_extra')
          foundField.setDisabled(value)
          _.set(this.form, 'data.with_extra', false)
        })
        .setSize(FieldSizes.half),
      new ObjectField()
        .setVisibleIf(() => this.meta.data.type === GenerateType.pdf)
        .setKey('photos')
        .setTitle(__('company.components.views.reports.projects-report-generate.form.fields.photos'))
        .setChildren([
          new Field()
            .setType(FieldTypes.checkbox)
            .setKey('add_photos')
            .setTitle(__('company.components.views.reports.projects-report-generate.form.fields.add-photos'))
            .setSize(FieldSizes.half),
          new Field()
            .setType(FieldTypes.checkbox)
            .setKey('only_extra')
            .setTitle(__('company.components.views.reports.projects-report-generate.form.fields.only-extra'))
            .setVisibleIf((values => values.photos.add_photos))
            .setSize(FieldSizes.half),
          new ObjectField()
            .setKey('period')
            .setTitle(__('company.components.views.reports.projects-report-generate.form.fields.period'))
            .setVisibleIf((values => ! values.photos.only_extra && values.photos.add_photos))
            .setDense(true)
            .setChildren([
              new Field()
                .setType(FieldTypes.datePicker)
                .setKey('from')
                .setTitle(__('company.components.views.reports.projects-report-generate.form.fields.from'))
                .setMeta({
                  min: moment(this.meta.data.from, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                })
                .setVisibleIf((values => values.photos.add_photos))
                .setSize(FieldSizes.half),
              new Field()
                .setType(FieldTypes.datePicker)
                .setKey('to')
                .setTitle(__('company.components.views.reports.projects-report-generate.form.fields.to'))
                .setMeta({
                  max: moment(this.meta.data.to, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                })
                .setVisibleIf((values => values.photos.add_photos))
                .setSize(FieldSizes.half),
            ]),
        ]),
      new ObjectField()
        .setTitle(__('company.components.views.reports.projects-report-generate.form.fields.invisible-columns'))
        .setKey('invisible_columns')
        .setChildren(this.columns.map((column: string) =>
          new Field()
            .setType(FieldTypes.checkbox)
            .setKey(column)
            .setTitle(__(`company.components.views.reports.projects-report-generate.form.fields.${ _.kebabCase(column) }`))
            .setSize(FieldSizes.half),
        )),
    ])
    .setChangeDataBeforeSubmit((data: any) => {
      data.invisible_columns = Object.keys(data.invisible_columns).filter((key: string) => data.invisible_columns[key])

      return data
    })
    .setInitialValues({
      with_extra: true,
      only_extra: false,
      photos: {
        period: {
          from: this.meta.data.from,
          to: this.meta.data.to,
        }
      }
    })
    .setSubmit({
      text: __('company.components.views.reports.projects-report-generate.form.submit')
    })
    .setInjectValues(this.meta.data)
    .setOnSuccess(async (data: any) => {
      this.form.setLoading(true)
      this.meta.onSuccess && await this.meta.onSuccess(data, this.form.data.invisible_columns)
      this.form.setLoading(false)
      this.$store.dispatch(GlobalActions.closeDialog)
    })

  loading: boolean = false
}
