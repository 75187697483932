import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'

export default class ObjectField extends Field {
  type: FieldTypes = FieldTypes.object
  subtitle!: string
  children: Field[] = []

  setSubtitle(subtitle: string): this {
    this.subtitle = subtitle
    return this
  }

  setChildren(children: Field[]): this {
    this.children = children
    return this
  }
}
